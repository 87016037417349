import {geoMap} from "../../main";

function create(): void {
    const btnString = `<button type="button" class="btn btn-light d-flex p-1 shadow leaflet-control" 
                             aria-label="Set view to the focused building"
                             id="centeringButton">
                        <span class="material-icons" role="presentation" aria-hidden="true">center_focus_weak</span>
                     </button>`;

    const container = document.querySelector(".leaflet-bottom.leaflet-right");
    container.insertAdjacentHTML("afterbegin", btnString);

    const btn = document.getElementById("centeringButton");
    btn.addEventListener("click", () => {
        geoMap.centerMapToBuilding(true);
    });
}

export default {
    create,
};
