import {GeoMap} from "./components/geoMap";
import HttpService from "./services/httpService";
import {translate} from "./utils/translate";
import LoadingIndicator from "./components/ui/loadingIndicator";
import Legend from "./components/ui/legend";
import CenterBtn from "./components/ui/centeringButton";
import searchForm from "./components/ui/searchForm";
import lightMode from "./components/ui/lightMode";
import navbar from "./components/ui/navbar"; 
import toggleLabel from "./components/ui/toggleLabel";
import descriptionArea from "./components/ui/descriptionArea";

export let geoMap: GeoMap = null;
document.addEventListener("DOMContentLoaded", function () {
    LoadingIndicator.start();
    HttpService.fetchOverpassData()
        .then(() => {
            geoMap = new GeoMap()
            navbar.render()
            toggleLabel.render()
            Legend.create()
            Legend.render()
            descriptionArea.render()
            lightMode.appendEvent()
            CenterBtn.create()
            searchForm.fillBuildingsList()
            translate()
            geoMap.applyBuildingAndLevelFromUrl()
            LoadingIndicator.end();
        })
        .catch((error) => {
            LoadingIndicator.error(error)
        })
})
