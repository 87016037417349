import colorService, { colors } from "../../services/colorService";
import symbolLegendModal from "./userProfileModal/symbolLegendModal";

const legend = document.getElementById("legend");
import { BUILDING_FILL_OPACITY } from "../../../public/strings/constants.json";
import { lang } from "../../services/languageService";
import { getCurrentLightMode } from "./lightMode";
import { ensureElementIsLoaded } from "../../utils/ensureElementIsLoaded";

function create(): void {
  const tbodyRef = document
    .getElementById("legendTable")
    .getElementsByTagName("tbody")[0];


  addLegendRecord(tbodyRef, colors.buildingWithoutIndoorMapOutlineColor, lang.legendBuildingWithoutIndoorMap, true);
  addLegendRecord(tbodyRef, colors.buildingWithIndoorMapOutlineColor, lang.legendBuildingWithIndoorMap, true);
  addLegendRecord(tbodyRef, colors.roomColor, lang.legendRoom);
  addLegendRecord(tbodyRef, colors.toiletColor, lang.legendToilet);
  addLegendRecord(tbodyRef, colors.stairsColor, lang.legendStairs);
  addLegendRecord(tbodyRef, colors.roomColorS, lang.legendSelected);
  addLegendRecordForSymbols(tbodyRef, lang.legendSymbols);

  document
    .getElementById("legendHeaderButton")
    .addEventListener("click", onCollapse);

  const labelString = document.createTextNode(lang.legendLabel);
  document.getElementById("legendLabel").prepend(labelString);
}

function addLegendRecord(
  ref: HTMLTableSectionElement,
  color: string,
  text: string,
  border?: boolean
): void {
  const row = ref.insertRow();
  let cell = row.insertCell();
  const colorBox = document.createElement("td");
  colorBox.setAttribute("height", "20");
  colorBox.setAttribute("width", "20");
  colorBox.style.backgroundColor = color;
  if (border) {
    const borderThickness = colorService.getLineThickness() * 0.1;
    colorBox.style.border = borderThickness + "px solid " + color;
    colorBox.style.opacity = BUILDING_FILL_OPACITY;
    colorBox.setAttribute("height", "" + (20 - borderThickness));
    colorBox.setAttribute("width", "" + (20 - borderThickness));
  }
  cell.appendChild(colorBox);
  cell = row.insertCell();
  const textNode = document.createTextNode("\u00A0" + text);
  cell.appendChild(textNode);
}

function addLegendRecordForSymbols(
  ref: HTMLTableSectionElement,
  text: string
): void {
  const row = ref.insertRow();
  let cell = row.insertCell();
  cell = row.insertCell();

  const p = document.createElement('p');
  p.innerHTML = '<a href="#" tabindex="0" role="link" title="' + text +'" aria-label="' + text + '">' + text + '</a>';
  p.addEventListener('click', symbolLegendModal.show);

  cell.appendChild(p);
}

function onCollapse() {
  const icon = document.getElementById("legendCollapseIcon");
  if (icon.innerHTML.startsWith("n")) {
    icon.innerHTML = "remove";
  } else {
    icon.innerHTML = "north_east";
  }
}

function render() : void {
  ensureElementIsLoaded('#legendWrapper', 50).then(()=>{
    getCurrentLightMode() === 'light' ? 
      (document.querySelector('#legendWrapper').classList.remove('dark', 'legend-dark'),
      document.getElementById('legendCollapseIcon').style.color = '#333')
      : (document.querySelector('#legendWrapper').classList.add('dark', 'legend-dark'),
      document.getElementById('legendCollapseIcon').style.color = 'white'); 
  });
}

export default {
  create,
  render
};
