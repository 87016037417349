import { getCurrentLightMode } from "./lightMode";

const label = document.getElementById('toggleLabel');
const toggleButton = document.getElementById('toggle');

function render() : void {
    getCurrentLightMode() === 'light' ? 
        (label.classList.add('text-black'), label.classList.remove('text-white'), toggleButton.style.color = "black")
        : (label.classList.add('text-white'), label.classList.remove('text-black'), toggleButton.style.color = "white"); 
    
}

export default {
    render,
}