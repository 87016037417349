import { getCurrentLightMode } from "./lightMode";

const navbar = document.querySelector('nav'); 

function render() : void {
    getCurrentLightMode() === 'light' ? 
        (navbar.classList.add('navbar-light'), navbar.classList.remove('navabr-dark', 'bg-primary'))
        : (navbar.classList.add('bg-primary', 'navbar-dark'), navbar.classList.remove('navbar-light'));
}

export default {
    render,
}