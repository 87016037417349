import {Modal} from "bootstrap";
import { SymbolInfo } from "../../../data/symbolLegend";
import {lang} from "../../../services/languageService";
import userVisualSettingsModal from "./userVisualSettingsModal";
import { ensureElementIsLoaded } from "../../../utils/ensureElementIsLoaded";
import { getCurrentLightMode } from "../lightMode";

const symbolLegendModal = new Modal(
    document.getElementById("symbolLegendModal"),
    {backdrop: "static", keyboard: false}
)

function render(): void {
    renderSymbolLegendList();

    document.getElementById("symbolLegendLabel").innerText =
        lang.legendSymbols;

        ensureElementIsLoaded('#symbolLegendModal', 50).then(() => {
            const symbolLegend = document.getElementById('symbolLegendModal').firstElementChild
                .firstElementChild;
            const bArrow = symbolLegend.querySelector('.bi') as HTMLElement;
            const closeBtn = symbolLegend.querySelector('.btn-close') as HTMLElement;
            getCurrentLightMode() === 'light' ?
                (symbolLegend.classList.remove('dark'), 
                bArrow.style.color = '#025558',
                closeBtn.classList.remove('btn-close-white'))
                : (symbolLegend.classList.add('dark'),
                bArrow.style.color = 'white',
                closeBtn.classList.add('btn-close-white'))
        })
}

function renderSymbolLegendList(): void {

    const table = document.createElement("table"); 
    const tbody = table.createTBody();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const iconSize = userVisualSettingsModal.getIconSize().toFixed(2) * 48;
    let i = 0;

    SymbolInfo.forEach((v, k) => {
        const row = tbody.insertRow(i);
        const cellImg = row.insertCell(0);
    
        const html = `<img src='/images/${v.icon}' width='${iconSize}px' height='${iconSize}px' style='padding-right:15px' alt='${v.alt}'
            long-desc='${v.longDesc}'>`
        cellImg.innerHTML = html;
    
        const cellName = row.insertCell(1);
        cellName.innerText = k;
    
        document.getElementById("symbolLegendCollection").appendChild(table);
        i++;
    });
}

function hide(): void {
    symbolLegendModal.hide()
}

function show(): void {
    symbolLegendModal.show();
    document.getElementById("symbolLegendCollection").focus();
    ensureElementIsLoaded('#symbolLegendModal', 50).then(() => {
        const symbolLegend = document.getElementById('symbolLegendModal').firstElementChild
            .firstElementChild;
        const bArrow = symbolLegend.querySelector('.bi') as HTMLElement;
        const closeBtn = symbolLegend.querySelector('.btn-close') as HTMLElement;
        getCurrentLightMode() === 'light' ?
            (symbolLegend.classList.remove('dark'), 
            bArrow.style.color = '#025558',
            closeBtn.classList.remove('btn-close-white'))
            : (symbolLegend.classList.add('dark'),
            bArrow.style.color = 'white',
            closeBtn.classList.add('btn-close-white'))
    })
  }

export default {show, hide, render}
