import { AccessibilityPropertiesInterface } from "../models/accessibilityPropertiesInterface";
import { UserGroupEnum } from "../models/userGroupEnum";
import { lang } from "../services/languageService";
import { ICONS, DARK_ICONS } from "../../public/strings/constants.json";
import { UserFeatureEnum } from "../models/userFeatureEnum";
import {getCurrentLightMode} from "../components/ui/lightMode";

const allGroups = [
  UserGroupEnum.blindPeople,
  UserGroupEnum.noImpairments,
  UserGroupEnum.wheelchairUsers,
];

/**
 * List of all possible accessibility features, which can be shown on click or by indication icons.
 * Currently, only the very FIRST hit in this list is shown.
 * So the most specific properties should be listed first, afterwards the more general ones.
 */
export const featureAccessibilityProperties: AccessibilityPropertiesInterface[] =
  [
    /* ================ blind people ================ */
    /* tactile information board / tactile map */
    {
      hasCorrectProperties: (f) =>
        f.properties.information !== undefined &&
        ["tactile_map", "tactile_model", "braille", "tactile_letters"].includes(
          f.properties.information
        ),
      hasNoProperties: (f) =>
        f.properties.information === undefined,
      msgTrue: lang.featureAccessibilityTactileBoard,
      msgFalse: null,
      msgUndefined: null,
      userGroups: [UserGroupEnum.blindPeople],
      iconFilename: getCurrentLightMode() === "light" ? ICONS.INFO : DARK_ICONS.INFO,
      tags: [UserFeatureEnum.tactileLines],
    },
    {
      hasCorrectProperties: (f) =>
        f.properties["speech_output:de"] !== undefined ||
        f.properties["speech_output:en"] !== undefined ||
        f.properties["speech_output"] !== undefined,
      hasNoProperties: (f) =>
        f.properties["speech_output:de"] === undefined &&
        f.properties["speech_output:en"] === undefined &&
        f.properties["speech_output"] === undefined,
      msgTrue: lang.featureAccessibilitySpeech,
      msgFalse: null,
      msgUndefined: null,
      iconFilename: getCurrentLightMode() === "light" ? ICONS.ELEVATOR : DARK_ICONS.ELEVATOR,
      userGroups: [UserGroupEnum.blindPeople],
      tags: [UserFeatureEnum.elevators],
    },

    /* ================ wheelchair users ================ */
    {
      hasCorrectProperties: (f) =>
        f.properties.amenity !== undefined &&
        f.properties.amenity === "toilets" &&
        f.properties.wheelchair !== undefined &&
        ["yes", "designated"].includes(f.properties.wheelchair),
      hasNoProperties: (f) =>
        f.properties.amenity === undefined &&
        f.properties.wheelchair === undefined,
      msgTrue: lang.featureAccessibilityAccessibleToilet,
      msgFalse: null,
      msgUndefined: null,
      userGroups: [UserGroupEnum.wheelchairUsers],
      iconFilename: getCurrentLightMode() === "light" ? ICONS.TOILETS_WHEELCHAIR : DARK_ICONS.TOILETS_WHEELCHAIR,
      tags: [UserFeatureEnum.accessibleToilets],
    },
    {
      hasCorrectProperties: (f) =>
        f.properties.highway !== undefined &&
        f.properties.highway === "elevator" &&
        f.properties.wheelchair !== undefined &&
        ["yes", "designated"].includes(f.properties.wheelchair),
      hasNoProperties: (f) =>
        f.properties.highway === undefined &&
        f.properties.wheelchair === undefined,
      msgTrue: lang.featureAccessibilityElevator,
      msgFalse: null,
      msgUndefined: null,
      userGroups: [UserGroupEnum.wheelchairUsers],
      iconFilename: getCurrentLightMode() === "light" ? ICONS.ELEVATOR : DARK_ICONS.ELEVATOR,
      tags: [UserFeatureEnum.elevators],
    },
    {
      hasCorrectProperties: (f) =>
        f.properties.wheelchair !== undefined &&
        ["yes", "designated"].includes(f.properties.wheelchair),
      hasNoProperties: (f) =>
        f.properties.wheelchair === undefined,
      msgTrue: lang.buildingAccessibilityWheelchairTrue,
      msgFalse: lang.buildingAccessibilityWheelchairFalse,
      msgUndefined: lang.buildingAccessibilityWheelchairUndefined,
      userGroups: [UserGroupEnum.wheelchairUsers],
      iconFilename: getCurrentLightMode() === "light" ? ICONS.WHEELCHAIR : DARK_ICONS.WHEELCHAIR,
      tags: [UserFeatureEnum.ramps, UserFeatureEnum.service],
    },
    {
      hasCorrectProperties: (f) =>
        f.properties["wheelchair:description:en"] !== undefined,
      hasNoProperties: (f) =>
        f.properties["wheelchair:description:en"] === undefined,
      msgTrue: (f) => f.properties["wheelchair:description:en"],
      msgFalse: null,
      msgUndefined: null,
      userGroups: [UserGroupEnum.wheelchairUsers],
    },
    {
      hasCorrectProperties: (f) =>
        f.properties["wheelchair:description:de"] !== undefined,
      hasNoProperties: (f) =>
        f.properties["wheelchair:description:de"] === undefined,
      msgTrue: (f) => f.properties["wheelchair:description:de"],
      msgFalse: null,
      msgUndefined: null,
      userGroups: [UserGroupEnum.wheelchairUsers],
    },

    /* ================ general features ================ */
    /* toilets - general (no wheelchair) */
    {
      hasCorrectProperties: (f) =>
        f.properties.amenity !== undefined &&
        f.properties.amenity === "toilets" &&
        (f.properties.wheelchair === undefined ||
          !["yes", "designated"].includes(f.properties.wheelchair)),
      hasNoProperties: (f) =>
        f.properties.amenity === undefined &&
        f.properties.wheelchair === undefined,
      msgTrue: (f) =>
        (f.properties.male !== undefined
          ? lang.featureAccessibilityMale
          : f.properties.female !== undefined
          ? lang.featureAccessibilityFemale
          : lang.featureAccessibilityUnisex) + lang.featureAccessibilityToilet,
      msgFalse: null,
      msgUndefined: null,
      userGroups: [UserGroupEnum.noImpairments, UserGroupEnum.blindPeople],
      iconFilename: getCurrentLightMode() === "light mode" ? ICONS.TOILETS : DARK_ICONS.TOILETS,
      tags: [UserFeatureEnum.toilets],
    },
    /* entrances (general) */
    {
      hasCorrectProperties: (f) =>
        f.properties.entrance !== undefined &&
        ["yes", "main", "secondary"].includes(f.properties.entrance),
      hasNoProperties: (f) =>
        f.properties.entrance === undefined,
      msgTrue: (f) =>
        (f.properties.entrance === "main"
          ? lang.featureAccessibilityMain
          : f.properties.entrance === "secondary"
          ? lang.featureAccessibilitySecondary
          : "") + lang.featureAccessibilityEntrance,
      msgFalse: null,
      msgUndefined: null,
      userGroups: allGroups,
      iconFilename: getCurrentLightMode() === "light" ? ICONS.ENTRANCE : DARK_ICONS.ENTRANCE,
      tags: [UserFeatureEnum.entrancesExits]
    },
    /* emergency exits (general) */
    {
      hasCorrectProperties: (f) =>
        (f.properties.exit !== undefined &&
          ["yes", "emergency"].includes(f.properties.exit)) ||
        (f.properties.entrance !== undefined &&
          ["exit", "emergency"].includes(f.properties.entrance)),
      hasNoProperties: (f) =>
          (f.properties.exit === undefined &&
          f.properties.entrance === undefined),
      msgTrue: lang.featureAccessibilityExit,
      msgFalse: null,
      msgUndefined: null,
      userGroups: allGroups,
      iconFilename: getCurrentLightMode() === "light" ? ICONS.EMERGENCY_EXIT : DARK_ICONS.EMERGENCY_EXIT,
      tags: [UserFeatureEnum.emergencyExits],
    },
    /* information boards (general, except blind people) */
    {
      hasCorrectProperties: (f) =>
        f.properties.information !== undefined &&
        ["board", "map"].includes(f.properties.information),
      hasNoProperties: (f) =>
        f.properties.information === undefined,
      msgTrue: lang.featureAccessibilityInformationBoard,
      msgFalse: null,
      msgUndefined: null,
      userGroups: [UserGroupEnum.noImpairments, UserGroupEnum.wheelchairUsers],
      iconFilename: getCurrentLightMode() === "light" ? ICONS.INFO : DARK_ICONS.INFO,
      tags: [UserFeatureEnum.service, UserFeatureEnum.tactileLines],
    },

    /* stairs (general, except wheelchair users) */
    {
      hasCorrectProperties: (f) =>
        (f.properties.highway !== undefined &&
          f.properties.highway === "steps") ||
        (f.properties.stairs !== undefined && f.properties.stairs === "yes"),
      hasNoProperties: (f) =>
        f.properties.highway === undefined &&
        f.properties.stairs === undefined,
      msgTrue: lang.userProfileStairs,
      msgFalse: null,
      msgUndefined: null,
      userGroups: [UserGroupEnum.noImpairments, UserGroupEnum.blindPeople],
      iconFilename: getCurrentLightMode() === "light" ? ICONS.STAIRS : DARK_ICONS.STAIRS,
      tags: [UserFeatureEnum.stairs],
    },
    /* catering general*/
    {
      hasCorrectProperties: (f) =>
        (f.properties.amenity !== undefined &&
          ["cafe", "restaurant", "bar", "fast_food"].includes(f.properties.amenity)),
      hasNoProperties: (f) =>
        f.properties.amenity === undefined ||
        (f.properties.amenity !== undefined &&
          !["cafe", "restaurant", "bar", "fast_food"].includes(f.properties.amenity)),
      msgTrue: (f) => 
         f.properties.amenity === "cafe" ?
           lang.featureAccessibilityCafee :
           f.properties.amenity === "restaurant" ?
           lang.featureAccessibilityRestaurant :
           f.properties.amenity === "bar" ?
           lang.featureAccessibilityBar :
           f.properties.amenity === "fast_food" ?
           lang.featureAccessibilityFastFood :
           lang.featureAccessibilityCatering,
      msgFalse: null,
      msgUndefined: null,
      userGroups: allGroups,
      iconFilename: getCurrentLightMode() === "light" ? ICONS.RESTAURANT : DARK_ICONS.RESTAURANT,
      tags: [UserFeatureEnum.catering],
    },
     /* shops general*/
     {
      hasCorrectProperties: (f) =>
          (f.properties.shop !== undefined),
      hasNoProperties: (f) =>
        f.properties.shop === undefined,
      msgTrue: (f) => 
           ["bakery", "pastry"].includes(f.properties.shop)?
           lang.featureAccessibilityBakery :
           lang.featureAccessibilityShop,
      msgFalse: null,
      msgUndefined: null,
      userGroups: allGroups,
      iconFilename: getCurrentLightMode() === "light" ? ICONS.SHOP : DARK_ICONS.SHOP,
      tags: [UserFeatureEnum.shops],
    },
    /* elevator general */
    {
      hasCorrectProperties: (f) =>
        f.properties.highway !== undefined &&
        f.properties.highway === "elevator",
      hasNoProperties: (f) =>
        f.properties.highway === undefined,
      msgTrue: lang.featureAccessibilityElevator,
      msgFalse: null,
      msgUndefined: null,
      userGroups: allGroups,
      iconFilename: getCurrentLightMode() === "light" ? ICONS.ELEVATOR : DARK_ICONS.ELEVATOR,
      tags: [UserFeatureEnum.elevators],
    },

    {
        hasCorrectProperties: (f) =>
            (f.properties.highway !== undefined && f.properties.highway === "elevator"),
        hasNoProperties: null, 
        msgTrue: lang.userProfileElevators,
        msgFalse: null,
        msgUndefined: null, 
        userGroups: allGroups,
        iconFilename: getCurrentLightMode() === "light" ? ICONS.ELEVATOR : DARK_ICONS.ELEVATOR,
        tags: [UserFeatureEnum.elevators],
    },
];
