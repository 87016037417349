import { lang } from "../services/languageService";
import {ICONS, DARK_ICONS} from "../../public/strings/constants.json";
import {getCurrentLightMode} from "../components/ui/lightMode";

const SymbolLegend: Map<string, any> = new Map<string, any>();
SymbolLegend.set(lang.legendSymbols, {
  name: lang.legendSymbols,
  linkedModal: "#symbolLegendModal"
});

const SymbolInfo: Map<string, any> = new Map<string, any>();
SymbolInfo.set(lang.legendSymbolsElevator, {
  icon: getCurrentLightMode() === 'light' ? ICONS.ELEVATOR : DARK_ICONS.ELEVATOR,
  alt: lang.altIconElevator, 
  longDesc: lang.longDescIconElevator
});

SymbolInfo.set(lang.legendSymbolsEmergencyExit, {
  icon: getCurrentLightMode() === 'light' ? ICONS.EMERGENCY_EXIT : DARK_ICONS.EMERGENCY_EXIT,
  alt: lang.altIconEmergencyExit, 
  longDesc: lang.longDescIconEmergencyExit
});

SymbolInfo.set(lang.legendSymbolsEntrance, {
  icon: getCurrentLightMode() === 'light' ? ICONS.ENTRANCE : DARK_ICONS.ENTRANCE,
  alt: lang.altIconEntrance, 
  longDesc: lang.longDescIconEntrance
});

SymbolInfo.set(lang.legendSymbolsInfo, {
  icon: getCurrentLightMode() === 'light' ? ICONS.INFO : DARK_ICONS.INFO,
  alt: lang.altIconInfo, 
  longDesc: lang.longDescIconInfo
});

SymbolInfo.set(lang.legendSymbolsRestaurant, {
  icon: getCurrentLightMode() === 'light' ? ICONS.RESTAURANT : DARK_ICONS.RESTAURANT,
  alt: lang.altIconRestaurant, 
  longDesc: lang.longDescIconRestaurant
});

SymbolInfo.set(lang.legendSymbolsShop, {
  icon: getCurrentLightMode() === 'light' ? ICONS.SHOP : DARK_ICONS.SHOP,
  alt: lang.altIconShop, 
  longDesc: lang.longDescIconShop
});

SymbolInfo.set(lang.legendStairs, {
  icon: getCurrentLightMode() === 'light' ? ICONS.STAIRS : DARK_ICONS.STAIRS,
  alt: lang.altIconStairs, 
  longDesc: lang.longDescIconStairs
});

SymbolInfo.set(lang.legendToilet, {
  icon: getCurrentLightMode() === 'light' ? ICONS.TOILETS : DARK_ICONS.TOILETS,
  alt: lang.altIconToilets, 
  longDesc: lang.longDescIconToilets
});

SymbolInfo.set(lang.legendSymbolsToiletWheelchair, {
  icon: getCurrentLightMode() === 'light' ? ICONS.TOILETS_WHEELCHAIR : DARK_ICONS.TOILETS_WHEELCHAIR,
  alt: lang.altIconToiletWheelchair, 
  longDesc: lang.longDescIconToiletWheelchair
});

SymbolInfo.set(lang.legendSymbolsAdditional, {
  icon: getCurrentLightMode() === 'light' ? ICONS.ADDITIONAL : DARK_ICONS.ADDITIONAL,
  alt: lang.altIconAdditional, 
  longDesc: lang.longDescIconAdditional
});

export { SymbolLegend, SymbolInfo };
