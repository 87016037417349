export const ensureElementIsLoaded = (selector: string, limit: number) =>
new Promise((resolve, reject) => {
  let count = 0;
  (function waitForElement() {
    const element = document.querySelector(selector);
    element ? resolve(element) : false; 
    if (limit && count > limit) return false;
    count += 1;
    setTimeout(waitForElement, 50);
  })();
});