import {lang} from "../../services/languageService";
import { ensureElementIsLoaded } from "../../utils/ensureElementIsLoaded";

const toggleButton = document.getElementById('toggle') as HTMLInputElement;
const navbar = document.querySelector('.navbar');
const currentBuildingLabel = document.querySelector('#currentBuilding');
const descriptionWrapper = document.querySelector('.descriptionAreaWrapper');
const description = document.querySelector('#descriptionArea');
const text = document.querySelectorAll('strong');
const legend = document.querySelector('#legendWrapper');
const profileWrapper = document.querySelector('.modal-content');
let label : HTMLInputElement;

let lightMode : string; 

window.onload = function () {
    label = document.querySelector("#toggleLabel");
    label.textContent = lang.invertColors;
    localStorage.getItem('lightMode') ? 
        lightMode = localStorage.getItem('lightMode')
        : (localStorage.setItem('lightMode', 'light'), lightMode = 'light'); 
    lightMode === 'light' ? 
        (label.classList.add('text-black'), label.classList.remove('text-white'))
        : (label.classList.add('text-white'), label.classList.remove('text-black'));
}

function appendEvent() : void {
    toggleButton.addEventListener('click', () => {
        toggleLight();
        toggleIcon();
    });
}

function toggleIcon() : void {
    ensureElementIsLoaded('#toggle', 60).then(() => {
        lightMode === 'dark' ? 
        (toggleButton.checked = true, toggleButton.style.color = "white")
        : (toggleButton.checked = false, toggleButton.style.color = "black"); 
    });
}

function toggleLight() : void {
    const tile = (document.getElementsByClassName('map-tiles-light').length > document.getElementsByClassName('map-tiles-dark').length) ?
        document.querySelector('.map-tiles-light') : document.querySelector('.map-tiles-dark');
    const card = new Promise(function (resolve, reject) {
        const cb = document.querySelectorAll('.card');
        if (cb.length > 0) {
            resolve("Class card body loaded");
        } else {
            reject("Class card body not loaded");
        }
    });

    const collection = new Promise(function (resolve, reject) {
        const collectionA = document.querySelectorAll('.collection a');
        if (collectionA.length > 0) {
            resolve("Class collection loaded");
        } else {
            reject("Class collection not loaded");
        }
    });

    if (lightMode === 'light') {
        localStorage.setItem('lightMode', 'dark');
        lightMode = 'dark';
        label.style.color = 'white';
        tile.classList.remove('map-tiles-light');
        tile.classList.add('map-tiles-dark');
        navbar.classList.remove('navbar-light');
        navbar.classList.add('bg-primary', 'navbar-dark');
        currentBuildingLabel.classList.remove('text-black');
        currentBuildingLabel.classList.add('text-white');
        descriptionWrapper.classList.remove('light');
        descriptionWrapper.classList.add('dark');
        description.classList.remove('light');
        description.classList.add('dark');
        text.forEach(el => el.style.color = 'white');
        legend.classList.add('legend-dark');
        profileWrapper.classList.add('profile-dark');
        collection.then(function () {
            const collectionA = document.querySelectorAll('.collection a');
            collectionA.forEach(el => el.classList.add('border-light', 'profile-dark'));
        });
        card.then(function () {
            document.querySelectorAll('.card').forEach(e => e.classList.add('dark'));
        });
        label.classList.add('text-white');
        label.classList.remove('text-black');
    } else {
        localStorage.setItem('lightMode', 'light');
        lightMode = 'light'; 
        label.style.color = 'black';
        tile.classList.remove('map-tiles-dark');
        tile.classList.add('map-tiles-light');
        navbar.classList.remove('bg-primary', 'navbar-dark')
        navbar.classList.add('navbar-light');
        currentBuildingLabel.classList.remove('text-white');
        currentBuildingLabel.classList.add('text-black');
        descriptionWrapper.classList.remove('dark');
        descriptionWrapper.classList.add('light');
        description.classList.remove('dark');
        description.classList.add('light');
        text.forEach(el => el.style.color = 'rgba(2, 85, 88)');
        legend.classList.remove('legend-dark');
        profileWrapper.classList.remove('profile-dark');
        collection.then(function () {
            const collectionA = document.querySelectorAll('.collection a');
            collectionA.forEach(el => el.classList.remove('border-light', 'profile-dark'));
        });
        card.then(function () {
            document.querySelectorAll('.card').forEach(e => e.classList.remove('dark'));
        });
        label.classList.add('text-black');
        label.classList.remove('text-white');
    }
    window.location.reload();
}

export function getCurrentLightMode() : string {
    if (localStorage.getItem('lightMode')) {
        return localStorage.getItem('lightMode');
    } else {
        return 'light';
    }
}

export default {
    appendEvent,
}