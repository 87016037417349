import {Modal} from "bootstrap"
import {COLOR_PROFS} from "../../../../public/strings/colorProfiles.json"
import userProfileModal from "./userProfileModal"
import colorService from "../../../services/colorService"
import {lang} from "../../../services/languageService"
import { getCurrentLightMode } from "../lightMode"

const userVisualSettingsModal = new Modal(
    document.getElementById("userVisualSettingsModal"),
    {backdrop: "static", keyboard: false}
)

const colorBlindnessList = document.getElementById("colorBlindnessList")
const contrastSettingsList = document.getElementById("contrastSettingsList")
const fontSizeButtons = document.getElementById("fontSizeButtons")
const modal = fontSizeButtons.parentNode

const state: {
    selectedColorProfile: string
    colorProfiles: string[]
    contrastSettings: {
        environmentOpacity: [opacity: number, name: string]
        colorStrength: [opacity: number, name: string]
        lineThickness: [opacity: number, name: string]
    },
    selectedFontSize: number
    fontSizes: string[][]
    iconSize: number
} = {
    selectedColorProfile: colorService.getCurrentProfile(),
    colorProfiles: COLOR_PROFS,
    contrastSettings: {
        environmentOpacity: [colorService.getEnvOpacity(), lang.environmentOpacity],
        colorStrength: [colorService.getColorStrength(), lang.colorStrength],
        lineThickness: [colorService.getLineThickness(), lang.lineThickness],
    },
    selectedFontSize: 16,
    fontSizes: [["1rem", "normal"], ["1.5rem", "größer"], ["2rem", "am größten"]],
    iconSize: localStorage.getItem('iconSize') ? parseFloat(localStorage.getItem('iconSize')) : 1.0,
}

function render(): void {
    renderColorBlindnessList()
    renderContrastSettingsList()
    renderFontSizeSelection()
    renderIconSizeSelection()

    document.getElementById("visualSettingsLabel").innerText =
        lang.visualSettingsLabel
    document.getElementById("colorBlindnessHeader").innerText =
        lang.colorBlindnessHeader
    document.getElementById("contrastSettingsHeader").innerText =
        lang.contrastSettingsHeader
    document.getElementById("fontSizeHeader").innerText =
        lang.fontSizeHeader

    const saveFeaturesButton = document.getElementById("saveVisualSettings")
    saveFeaturesButton.onclick = () => onSave()
    const modalContent = modal.parentElement
    const bArrow = modalContent.querySelector('.bi') as HTMLElement
    const closeBtn = modalContent.querySelector('.btn-close') as HTMLElement
    if (getCurrentLightMode() === 'dark') {
        modalContent.classList.add('profile-dark')
        bArrow.style.color = 'white',
        closeBtn.classList.add('btn-close-white')
    } else {
        modalContent.classList.remove('profile-dark')
        bArrow.style.color = '#025558',
        closeBtn.classList.remove('btn-close-white')
    }
}

function renderIconRange() {
    const range_div = document.createElement("div")
    range_div.innerHTML = `<label for="iconSize" class="form-label"><h6>${lang.iconSizeHeader}</h6></label>
    <input type="range" class="form-range" id="iconSize" step="0.1" min="0.5" max="2" value="${
        state.iconSize
    }">`

    range_div.onchange = (e: Event) => {
        state.iconSize = +(<HTMLInputElement>e.target).value;
    }

    return range_div
}

function renderIconSizeSelection() {
    modal.appendChild(renderIconRange())

}

function renderFontSizeSelection(): void {
    const buttons = document.createElement("div")
    buttons.classList.add("btn-group")
    buttons.setAttribute("group", "group")
    buttons.setAttribute("aria-label", lang.fontSizeButtons)

    state.fontSizes.forEach(fontSize => {
        const button = document.createElement("button")
        button.classList.add("btn", "btn-light")
        getCurrentLightMode() === 'light' ?
            (button.classList.add('btn-light'), button.classList.remove('btn-green', 'border-light'))
            : (button.classList.remove('btn-light'), button.classList.add('btn-green','border-light'))
        button.setAttribute("type", "button")
        button.innerText = fontSize[1]
        button.addEventListener("click", () =>
            document.documentElement.style.setProperty("font-size", fontSize[0]))
        buttons.appendChild(button)
    })

    fontSizeButtons.append(buttons)
}

function renderColorBlindnessList(): void {
    const {colorProfiles: profiles} = state

    profiles.map((p) => {
        document.getElementById("colorBlindnessList").append(renderCheckbox(p))
    })
}

function renderCheckbox(profile: string): HTMLDivElement {
    const checkbox_div = document.createElement("div")
    const checkbox = document.createElement("input")
    const label = document.createElement("label")

    const {selectedColorProfile} = state

    checkbox_div.className = "form-check"
    checkbox.className = "form-check-input"
    checkbox.type = "checkbox"
    checkbox.id = profile

    checkbox.checked = profile === selectedColorProfile

    checkbox.onchange = (e: Event) => {
        state.selectedColorProfile = (<HTMLElement>e.currentTarget).id

        handleChange()
    }

    label.className = "form-check-label"
    label.htmlFor = profile
    label.innerText = colorService.getCurrentColorTranslation(profile)

    checkbox_div.appendChild(checkbox)
    checkbox_div.appendChild(label)

    return checkbox_div
}

function renderContrastSettingsList(): void {
    const {contrastSettings} = state

    Object.keys(contrastSettings).map((s) => {
        contrastSettingsList.append(renderRangeInput(s))
    })
}

function renderRangeInput(name: string): HTMLDivElement {
    type prop = keyof typeof state.contrastSettings
    const range_div = document.createElement("div")
    range_div.innerHTML = `<label for="${name}" class="form-label">${
        state.contrastSettings[name as prop][1]
    }</label>
    <input type="range" class="form-range" id="${name}" step="10" min="0" max="100" value="${
        state.contrastSettings[name as prop][0]
    }">`

    range_div.onchange = (e: Event) => {
        type prop = keyof typeof state.contrastSettings
        const prop = (<HTMLElement>e.target).id

        state.contrastSettings[prop as prop][0] = +(<HTMLInputElement>e.target)
            .value
    }
    return range_div
}

function handleChange() {
    colorBlindnessList.innerHTML = ""
    renderColorBlindnessList()
}

function onSave() {
    // featureService.setCurrentFeatures(checkboxState)
    userProfileModal.hideAll()

    colorService.setCurrentProfile(state.selectedColorProfile)
    colorService.setEnvOpacity(state.contrastSettings.environmentOpacity[0])
    colorService.setColorStrength(state.contrastSettings.colorStrength[0])
    colorService.setLineThickness(state.contrastSettings.lineThickness[0])
    localStorage.setItem('iconSize', state.iconSize.toString());
    state.iconSize = parseFloat(localStorage.getItem('iconSize'));

    /*
     * Hack: reload window location to properly update all profile-specific information.
     * Relevant data is stored in localStorage and remains persistent after reload.
     */
    setTimeout(window.location.reload.bind(window.location), 200)
}

function hide(): void {
    userVisualSettingsModal.hide()
}

export function getIconSize() {
    return state.iconSize
}

export default {hide, render, getIconSize}
