//TODO single update method???
import { getBuildingGeneralInformation } from '../../services/buildingService';
import { lang } from "../../services/languageService";
import levelService from '../../services/levelService';
import { getCurrentLightMode } from './lightMode';
import { ensureElementIsLoaded } from '../../utils/ensureElementIsLoaded';

let buildingDescShown = false;
let timeLastClick = 0.0;

function handleBuildingDescription(titleAreaId: string, timeStampOfEvent: number, elementId = "description") {
  if(timeStampOfEvent - timeLastClick < 0.1){
    return; 
  }

  timeLastClick = timeStampOfEvent;
  const descriptionArea = document.getElementById(elementId);
  buildingDescShown = !buildingDescShown;

  const titleArea = document.getElementById(titleAreaId);

  if(buildingDescShown){
    descriptionArea.innerHTML = '<h3>' + getBuildingGeneralInformation() + '</h3>';
    const title = '<a tabindex="0" role="button"> ' + lang.hideBuildingDescription + '</a>';
    const newTitle = titleArea.innerHTML.replace(/<a(.*)/, title);
    titleArea.innerHTML = newTitle;
  }else{
    descriptionArea.innerHTML = '';
    const title = '<a tabindex="0" role="button"> ' + lang.showBuildingDescription + '</a>';
    const newTitle = titleArea.innerHTML.replace(/<a(.*)/, title);
    titleArea.innerHTML = newTitle;
    updateLayerDescription(levelService.getCurrentLevelDescription());
  }
}

/*
    Update general information/headers
 */
function updateBuilding(buildingTitle: string, elementId = "selectedBuilding"): void {
  const titleArea = document.getElementById(elementId);

  if(elementId === 'selectedBuilding'){
    titleArea.innerHTML = '<h1 style="display: inline">' + buildingTitle + '</h1>' + '<a tabindex="0" role="button"> ' + lang.showBuildingDescription + '</a>';
    buildingDescShown = false;
    titleArea.addEventListener('click', (e) => {handleBuildingDescription(elementId, e.timeStamp);});
  }
  document.getElementById("descriptionArea").focus();
}

/*
    Update floor description
 */
function updateLayerDescription(message: string, elementId = "description"): void {
  const popUpArea = document.getElementById(elementId);
  popUpArea.innerHTML = message;
  document.getElementById("descriptionArea").focus();
}

/*
    Update feature description
 */
function updateFeatureDescription(message: string, elementId = "description"): void {
  const popUpArea = document.getElementById(elementId);
  popUpArea.innerHTML = message;
  document.getElementById("descriptionArea").focus();
}


/*
  Change Description Area Color
*/
function render():void {
  ensureElementIsLoaded('.descriptionAreaWrapper', 50).then( () => {
    getCurrentLightMode() === 'light' ?
      (document.querySelector(".descriptionAreaWrapper").classList.add('light'), 
      document.querySelector(".descriptionAreaWrapper").classList.remove('dark'))
      : (document.querySelector(".descriptionAreaWrapper").classList.add('dark'),
        document.querySelector(".descriptionAreaWrapper").classList.remove('light')); 
    }
  ); 

  ensureElementIsLoaded('#descriptionArea', 50).then( () => {
    getCurrentLightMode() === 'light' ?
      (document.querySelector("#descriptionArea").classList.add('light'), 
      document.querySelector("#descriptionArea").classList.remove('dark'),
      document.querySelectorAll('strong').forEach(el => el.style.color = 'rgb(2, 85, 88)'))
      : (document.querySelector("#descriptionArea").classList.add('dark'),
        document.querySelector("#descriptionArea").classList.remove('light'),
        document.querySelectorAll('strong').forEach(el => el.style.color = 'white')); 
    }
  ); 
}

export default {
  updateBuilding,
  updateLayerDescription,
  updateFeatureDescription,
  render,
};
